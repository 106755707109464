// src/components/Home.tsx

import React from 'react';
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  homeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    overflow: 'hidden',
  },
  homeLogo: {
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
  },
}));

const Home: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.homeContainer}>
      <img src={`/logo.png`} alt="Logo" className={classes.homeLogo} />
    </div>
  );
};

export default Home;